import React from "react";

class Calculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calcText: "0",
      history: [
        {
          text: 0,
          operator: "=",
        },
      ],
      undoHistory: [],
      total: 0,
      clearNext: true,
      lastOperator: "+",
    };

    this.changeText = this.changeText.bind(this);
    this.calcOperator = this.calcOperator.bind(this);
    this.undo = this.undo.bind(this);
    this.redo = this.redo.bind(this);
  }

  changeText(e) {
    let calcText = this.state.calcText;
    if (
      calcText.includes(".") &&
      e.target.value === "." &&
      !this.state.clearNext
    ) {
      return;
    }
    if (this.state.clearNext) {
      calcText = e.target.value;
    } else {
      calcText += e.target.value;
    }

    this.setState({
      calcText,
      clearNext: false,
    });
  }

  undo() {
    const lastOperatrion = this.state.history.slice(-1).pop();
    if (
      typeof lastOperatrion === "undefined" ||
      this.state.history.length === 1
    ) {
      console.log("Nothing left to undo");
      return;
    }

    console.log("Need to undo: ", lastOperatrion);

    let total = this.state.total;
    let history = this.state.history;

    let undoHistory = this.state.undoHistory;
    undoHistory.push(lastOperatrion);

    switch (lastOperatrion.operator) {
      case "+":
        total -= parseFloat(lastOperatrion.text);
        console.log("Total: ", total);
        break;

      case "-":
        total += parseFloat(lastOperatrion.text);
        console.log("Total: ", total);
        break;

      case "*":
        total /= parseFloat(lastOperatrion.text);
        console.log("Total: ", total);
        break;

      case "/":
        total *= parseFloat(lastOperatrion.text);
        console.log("Total: ", total);
        break;
    }
    history.pop();
    console.log(history);

    this.setState({
      total,
      history,
      undoHistory,
      calcText: "" + total,
    });
  }

  redo() {
    const lastOperatrion = this.state.undoHistory.slice(-1).pop();
    if (
      typeof lastOperatrion === "undefined" ||
      this.state.undoHistory.length === 0
    ) {
      console.log("Nothing left to redo");
      return;
    }

    console.log("Need to undo: ", lastOperatrion);

    let total = this.state.total;
    let history = this.state.history;

    let undoHistory = this.state.undoHistory;
    history.push(lastOperatrion);

    switch (lastOperatrion.operator) {
      case "+":
        total += parseFloat(lastOperatrion.text);
        console.log("Total: ", total);
        break;

      case "-":
        total -= parseFloat(lastOperatrion.text);
        console.log("Total: ", total);
        break;

      case "*":
        total *= parseFloat(lastOperatrion.text);
        console.log("Total: ", total);
        break;

      case "/":
        total /= parseFloat(lastOperatrion.text);
        console.log("Total: ", total);
        break;
    }
    undoHistory.pop();
    console.log(history);

    this.setState({
      total,
      history,
      undoHistory,
      calcText: "" + total,
    });
  }

  calcOperator(e) {
    const operator = e.target.value;
    const lastOperator = this.state.lastOperator;

    let total = this.state.total;
    let history = this.state.history;
    const clearNext = this.state.clearNext;
    if (clearNext) {
      this.setState({
        lastOperator: e.target.value,
      });
      return;
    }
    switch (lastOperator) {
      case "+":
        total += parseFloat(this.state.calcText);
        console.log("Total: ", total);
        break;

      case "-":
        total -= parseFloat(this.state.calcText);
        console.log("Total: ", total);
        break;
      case "*":
        total *= parseFloat(this.state.calcText);
        console.log("Total: ", total);
        break;
      case "/":
        total /= parseFloat(this.state.calcText);
        console.log("Total: ", total);
        break;
      case "=":
        break;
    }

    history.push({
      text: this.state.calcText,
      operator: lastOperator,
    });
    this.setState({
      calcText: "" + total,
      total,
      history,
      undoHistory: [],
      clearNext: true,
      lastOperator: operator,
    });
  }

  render() {
    return (
      <div className="container my-4">
        <div className="calculator card">
          <input
            type="text"
            value={this.state.calcText}
            className="calculator-screen z-depth-1"
            disabled
          />

          <div className="calculator-keys">
            <button
              type="button"
              className="operator btn btn-info"
              onClick={this.calcOperator}
              value="+"
            >
              +
            </button>
            <button
              type="button"
              className="operator btn btn-info"
              onClick={this.calcOperator}
              value="-"
            >
              -
            </button>
            <button
              type="button"
              className="operator btn btn-info"
              onClick={this.calcOperator}
              value="*"
            >
              &times;
            </button>
            <button
              type="button"
              className="operator btn btn-info"
              onClick={this.calcOperator}
              value="/"
            >
              &divide;
            </button>

            <button
              type="button"
              onClick={this.changeText}
              value="7"
              className="btn btn-light waves-effect"
            >
              7
            </button>
            <button
              type="button"
              onClick={this.changeText}
              value="8"
              className="btn btn-light waves-effect"
            >
              8
            </button>
            <button
              type="button"
              onClick={this.changeText}
              value="9"
              className="btn btn-light waves-effect"
            >
              9
            </button>

            <button
              type="button"
              onClick={this.changeText}
              value="4"
              className="btn btn-light waves-effect"
            >
              4
            </button>
            <button
              type="button"
              onClick={this.changeText}
              value="5"
              className="btn btn-light waves-effect"
            >
              5
            </button>
            <button
              type="button"
              onClick={this.changeText}
              value="6"
              className="btn btn-light waves-effect"
            >
              6
            </button>

            <button
              type="button"
              onClick={this.changeText}
              value="1"
              className="btn btn-light waves-effect"
            >
              1
            </button>
            <button
              type="button"
              onClick={this.changeText}
              value="2"
              className="btn btn-light waves-effect"
            >
              2
            </button>
            <button
              type="button"
              onClick={this.changeText}
              value="3"
              className="btn btn-light waves-effect"
            >
              3
            </button>

            <button
              type="button"
              onClick={this.changeText}
              value="0"
              className="btn btn-light waves-effect"
            >
              0
            </button>
            <button
              type="button"
              onClick={this.changeText}
              className="decimal function btn btn-secondary"
              value="."
            >
              .
            </button>
            <button
              type="button"
              className="all-clear function btn btn-danger btn-sm"
              value="all-clear"
            >
              AC
            </button>

            <button
              type="button"
              onClick={this.calcOperator}
              className="equal-sign operator btn btn-default"
              value="="
            >
              =
            </button>
          </div>

          <div className="addPadding">&nbsp;</div>
          <div className="calculator-keys">
            <button
              type="button"
              onClick={this.undo}
              className="all-clear function btn btn-danger btn-sm"
              value="all-clear"
            >
              Undo
            </button>
            <button
              type="button"
              onClick={this.redo}
              className="all-clear function btn btn-danger btn-sm"
              value="all-clear"
            >
              Redo
            </button>
          </div>
        </div>
        History:
        {this.state.history.map((h) => (
          <li key={h.text}>
            {h.operator} {h.text}
          </li>
        ))}
        Undo History:
        {this.state.undoHistory.map((h) => (
          <li key={h.text}>
            {h.operator} {h.text}
          </li>
        ))}
      </div>
    );
  }
}
export default Calculator;
